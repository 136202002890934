import React from "react"
import { graphql } from "gatsby"
import { Box, Heading, Text } from "theme-ui"
import excerptHtml from "excerpt-html"
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import Parser from "html-react-parser"

// import app components
import Edges from "../../components/Edges"
import Layout from "../../components/Layout"
import BackgroundImage from "../../components/BackgroundImage"
import Button from "../../components/Button"
import Link from "../../components/Link"
import Textarea from "../../components/Textarea"
import TextImage from "../../components/textImage/TextImage"
import Carousel from "../../components/Carousel"

import useThemeOptions from "../../hooks/useThemeOptions"

const Template = (props) => {
  const {
    data: {
      wpProductCat: { name, seo },
      allWpProduct: { nodes: products },
    },
  } = props

  const {
    categories: { items },
  } = useThemeOptions()

  const sections = items.filter(
    (o, i) => o.id.toLowerCase() === name.toLowerCase()
  )

  return (
    <>
      <Layout {...props} seo={seo}>
        <TextImage sections={sections} />
        <Box sx={{ backgroundColor: "bgWhite" }}>
          <Edges size="md">
            <Box sx={{ textAlign: "center", pt: [50, 70], pb: 50 }}>
              {sections[0]?.headline && (
                <Heading
                  as="h1"
                  variant="text.callToActionHeading"
                  sx={{
                    color: "text",
                    pb: 24,
                  }}
                >
                  {Parser(sections[0].headline)}
                </Heading>
              )}
              {sections[0]?.subHeadline && (
                <Textarea content={sections[0].subHeadline} />
              )}
            </Box>
            <Box
              sx={{
                pt: 50,
                pb: [50, 70],
                display: "flex",
                a: { textDecoration: "none", color: "primary" },
                flexWrap: "wrap",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <GatsbyLink to="/">
                <Text
                  variant="text.category"
                  sx={{
                    color: "primary",
                    "&:hover": { color: "black" },
                  }}
                  children="HOME"
                />
              </GatsbyLink>
              <Text
                px={2}
                variant="text.category"
                children="/"
                sx={{ transform: "translateY(-2px)" }}
              />
              <Link to="/products">
                <Text
                  variant="text.category"
                  sx={{ color: "primary", "&:hover": { color: "black" } }}
                  children="ALL BATTERIES"
                />
              </Link>
              <Text
                px={2}
                variant="text.category"
                children="/"
                sx={{ transform: "translateY(-2px)" }}
              />
              <Text variant="text.category" children={name.toUpperCase()} />
            </Box>
          </Edges>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
              bg: "bgWhite",
              pb: ["50px", "100px"],
            }}
          >
            <Edges size="md">
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["1fr", "1fr 1fr"],
                  "@media (min-width: 1000px)": {
                    gridTemplateColumns: "1fr 1fr 1fr",
                  },
                  gridTemplateRows: "auto",
                  gap: "30px 10px",
                  width: "auto",
                  mb: 4,
                }}
              >
                {products.length > 0 ? (
                  products.map((o, idx) => (
                    <ProductCard key={idx} to={o.uri}>
                      <Box
                        sx={{
                          position: "relative",
                          height: 200,
                          width: "100%",
                        }}
                      >
                        {o.acf?.section1?.image && (
                          <BackgroundImage image={o.acf?.section1?.image} />
                        )}
                      </Box>
                      <Edges>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            position: "relative",
                            py: 4,
                            gap: 3,
                          }}
                        >
                          {o.title && (
                            <Link to={o.uri}>
                              <Heading
                                variant="styles.productsHeading"
                                sx={{ pb: 3 }}
                              >
                                {o.title}
                              </Heading>
                            </Link>
                          )}

                          {o.acf?.section1?.text && (
                            <Box
                              sx={{
                                pb: 2,
                              }}
                            >
                              <Textarea
                                content={excerptHtml(o.acf?.section1?.text, {
                                  pruneLength: 120,
                                })}
                              />
                            </Box>
                          )}

                          {
                            <Button
                              customeVariant="buttons.products"
                              to={o.uri}
                            >
                              More
                            </Button>
                          }
                        </Box>
                      </Edges>
                    </ProductCard>
                  ))
                ) : (
                  <>
                    <Heading
                      variant="styles.h5"
                      sx={{ fontSize: "6", mt: 18, ml: 18 }}
                    >
                      No Results
                    </Heading>
                    <Text
                      variant="text.introduction"
                      sx={{
                        display: "block",
                        letterSpacing: "-0.23px",
                        lineHeight: "25px",
                        mt: 18,
                        ml: 18,
                        mb: 18,
                      }}
                    >
                      Please try a different query
                    </Text>
                  </>
                )}
              </Box>
            </Edges>
          </Box>
        </Box>

        <Carousel
          items={items.filter((o) => o.id.toLowerCase() !== name.toLowerCase())}
        />
      </Layout>
    </>
  )
}

const ProductCard = styled(Link)`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`
export const Query = graphql`
  query ProductCat($id: String!) {
    wpProductCat(id: { eq: $id }) {
      uri
      name
      description
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
    }

    allWpProduct(
      filter: { productCat: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        uri
        acf {
          fieldGroupName
          section1 {
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Template
