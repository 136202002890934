import React, { useState } from "react"
import { Box, Heading, Text, Divider, Grid, Progress, Input } from "theme-ui"
import Slider from "react-slick"
import Parser from "html-react-parser"
import excerptHtml from "excerpt-html"
import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"

// import app components
import Edges from "./Edges"
import "../styles/slick/slick.css"
import "../styles/slick/slick-theme.css"
import Textarea from "./Textarea"
import BackgroundImage from "./BackgroundImage"
import Link from "./Link"
import Button from "./Button"

const Carousel = (props) => {
  const { items } = props

  const [slideIndex, setSlideIndex] = useState(0)
  const [updateCount, setUpdateCount] = useState(0)

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    autoplaySpeed: 8000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    customPaging: function (i) {
      return (
        <Edges size="md">
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box
              className="activeDot"
              sx={{
                width: ["50px", "100px"],
                height: "3px",
                backgroundColor: "#C8C8C8	",
              }}
            />
          </Box>
        </Edges>
      )
    },
    // afterChange: () => setSlideIndex(slideIndex + 1),
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Box
      sx={{
        bg: "bgWhite",
        py: "30px",
        ".progressBar": {
          width: "50%",
          height: "5px",
          bg: "primary !important",
          input: {
            "&:-webkit-slider-thumb": {
              display: "none",
            },
          },
        },
        ".slick-track": {
          ml: [0, "100px", "200px"],
          mb: "100px",
        },
        ".slick-slide": {
          pr: "15px",
          maxWidth: "350px",
        },
        ".slick-active": {
          " .activeDot": {
            color: "#8C9D72 !important",
            bg: "#8C9D72 !important",
          },
        },
        ".slick-slider": {
          ".slick-dots": {
            textAlign: "left !important",
            pl: [0, "100px", "200px"],
            mb: "50px",
            li: {
              width: ["50px", "100px"],
              height: "3px",
              margin: "0 !important",
              ".current": {
                ":hover": {
                  backgroundColor: "#8C9D72 !important",
                  color: "#8C9D72 !important",
                },
              },
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row", "row"],
          alignItems: "flex-start",
          pl: [0, "100px", "200px"],
          pb: "10px",
        }}
      >
        <Heading
          children={"Other Categories"}
          variant="styles.productsHeading"
          sx={{}}
        />
      </Box>
      <Slider ref={(slider) => (slider = slideIndex)} {...settings}>
        {items &&
          items.map((o, i) => {
            return (
              <CardContainer to={o.link.url} key={o.id}>
                <Box
                  sx={{
                    position: "relative",
                    height: 200,
                    width: "100%",
                  }}
                >
                  {o.image && <BackgroundImage image={o.image} />}
                </Box>
                <Edges>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      position: "relative",
                      py: 4,
                      gap: 3,
                    }}
                  >
                    {o.id && (
                      <Heading variant="styles.productsHeading" sx={{ pb: 2 }}>
                        {o.id}
                      </Heading>
                    )}

                    {o.text && (
                      <Box
                        sx={{
                          pb: 2,
                        }}
                      >
                        <Textarea
                          content={excerptHtml(o.text, {
                            pruneLength: 100,
                          })}
                        />
                      </Box>
                    )}
                    <Button customeVariant="buttons.products" to={o.link.url}>
                      More
                    </Button>
                  </Box>
                </Edges>
              </CardContainer>
            )
          })}
      </Slider>
      {/*
      
      <Edges size="md">
        <input
          onChange={(e) => setSlideIndex(parseInt(e.target.value))}
          value={slideIndex}
          type="range"
          min={0}
          max={3}
          className="progressBar"
        />
      </Edges>
      */}
    </Box>
  )
}

const CardContainer = styled(GatsbyLink)`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  transition: 0.3s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none !important;
  color: black !important;
`

export default Carousel
