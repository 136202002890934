import React from "react"
import { Box, Heading } from "theme-ui"

// import app components
import Textarea from "../Textarea"
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Button from "../Button"
import BackLogo from "../../icons/textImage-bg.svg"

const TextImage = (props) => {
  const { sections, headline, url } = props

  return (
    <>
      {sections &&
        sections.map((o, i) => (
          <Box
            key={i}
            sx={{
              bg: `${o.backgroundcolor === "white" ? "white" : "lightBlue"}`,
              position: "relative",
              zIndex: 1,
              display: ["flex", "flex", "grid"],
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "1fr",
              flexWrap: "wrap-reverse",
              alignItems: "center",
              "p:last-child": {
                mb: [16, 18, 24],
              },
            }}
          >
            <Box
              sx={{
                bg: "white",
                gridColumnStart: `${o.alignimage === "Right" ? 2 : 1}`,
                gridColumnEnd: `${o.alignimage === "Right" ? 3 : 2}`,
                gridRowStart: 1,
                gridRowEnd: 2,
                zIndex: 10,
                left: o.alignimage === "right" ? "unset" : 0,
                right: o.alignimage === "left" ? "unset" : 0,
                position: ["relative", null, "absolute"],
                height: [200, 300, "auto"],
                width: "100%",
                order: 1,
                top: [null, null, 0],
                bottom: [null, null, 0],
                "@media (min-width:52em)": {
                  clipPath: `${
                    o.alignimage === "Right"
                      ? "polygon(0 0, 100% 0%, 100% 100%, 15% 100%)"
                      : "polygon(0 0, 100% 0%, 90% 100%, 0% 100%)"
                  }`,
                },
              }}
            >
              {o.image && <BackgroundImage image={o.image} />}
            </Box>
            <Box
              sx={{
                position: "relative",
                zIndex: 10,
                gridColumnStart: `${o.alignimage === "Right" ? 1 : 2}`,
                gridColumnEnd: `${o.alignimage === "Right" ? 2 : 3}`,
                gridRowStart: 1,
                gridRowEnd: 2,
                width: "100%",
                height: "100%",
                px: ["null", "80px"],
                "@media (min-width: 1100px)": {
                  pl: [
                    "null",
                    `${o.alignimage === "Right" ? "180px" : "80px"}`,
                  ],
                  pr: [
                    "null",
                    `${o.alignimage === "Right" ? "80px" : "180px"}`,
                  ],
                },
                pt: ["40px", "100px"],
                pb: 40,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                "div > *": {
                  color: `${o.backgroundcolor === "white" ? "text" : "white"}`,
                },
              }}
            >
              <Edges size="md">
                {headline && (
                  <Heading children={headline} variant="styles.postHeader" />
                )}
                {o.text && <Textarea content={o.text} />}
                {o.link && o.link.title && (
                  <Button customeVariant="buttons.secondary" to={o.link.url}>
                    {o.link.title}
                  </Button>
                )}
              </Edges>
            </Box>
            <Box
              sx={{
                right: 0,
                position: "absolute",
                bottom: 0,
                zIndex: 1,
                mixBlendMode: "soft-light",
                opacity: [0.3, 0.5],
                svg: {
                  height: "80%",
                  width: "650px",
                },
              }}
            >
              <BackLogo />
            </Box>
          </Box>
        ))}
    </>
  )
}
export default TextImage
